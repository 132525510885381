<template>
  <div class="accommodation-item" :style="accommodationStyle" @mouseenter="mouseOverCard" @mouseleave="mouseLeaveCard">
    <b-row>
      <b-col cols="12" :lg="openedMap ? '5': '4'" class="pr-lg-0" :class="{ 'pr-lg-0': openedMap }">
        <div class="accommodation-slider-container">
          <VueperSlides :slide-ratio="1" fixed-height="200px" :bullets="false" infinite :arrows="true"
                        :touchable="false">
            <VueperSlide v-for="(image, index) in accommodation.images" :key="index">
              <template v-bind:class="[ image.pathname.split('.')[1]==='mp4' ? 'video-container' : '']" #content>
                <div v-if="image.pathname.split('.')[1]!=='mp4'" class="vueperslide__content-wrapper">
                  <b-img :src="image.smallApartmentImageFilepath || image.pathname || null | getAsset" class="sliderCursor" fluid
                         @click="showModal(index)"/>
                </div>
                <video v-if="image.pathname.split('.')[1]==='mp4'" autoplay muted loop playsinline class="sliderCursor"
                       @click="showModal(index)">
                  <source :src="image.pathname | getAsset" type="video/mp4">
                </video>
              </template>
            </VueperSlide>
          </VueperSlides>
        </div>
      </b-col>
      <b-col cols="12" :lg="openedMap ? '7' :'8'" class="d-flex flex-column justify-content-between pl-lg-0">
        <div @click="selectAccommodation()" class="accommodation-info p-4">
          <div class="accommodation-name">
            {{ accommodation.name || `${accommodation.type} ${accommodation.subtype}` }}
            <div class="accommodation-address">
              {{
                `${accommodation.addressStreet} ${accommodation.addressNumber}, ${accommodation.addressZipCode || ''} ${accommodation.addressCity}`
              }}
            </div>
          </div>
          <div class="accommodation-icons">
            <div class="icons">
              <span class="accommodation-icon">
                <FontAwesomeIcon :icon="['fas', 'bed']"/> {{ accommodation.numOfBeds }}
              </span>
              <span class="accommodation-icon">
                <FontAwesomeIcon :icon="['fas', 'shower']"/>
                <span v-if="isApartment">{{ accommodation.numOfBathrooms }}</span>
                <span v-else>{{ accommodation.bathroomType | capitalize }}</span>
              </span>
              <span class="accommodation-icon">
                <FontAwesomeIcon :icon="['fas', 'user']"/> {{ accommodation.numOfPerson }}
              </span>
            </div>
            <div class="price text-right">
              <RFButton :label="`${accommodation.monthlyAmount}€/${$t('month')}`" :rounded="false" background="#fff"
                        :color="isApartment && isSearchPage || !isSearchPage ? '#67AEA6' : '#E26F5A'"/>
              <!--RFButton :label="`${accommodation.type.priceTag}€/${$t('month')}`" :rounded="false" background="#fff" :color="isApartment && isSearchPage || !isSearchPage ? '#67AEA6' : '#E26F5A'"/-->
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal hide-footer v-model="showCarouselModal" title="Submit Your Name">
      <VueperSlides fixed-height="85vh" arrows-outside fractions :touchable="false">
        <VueperSlide v-for="(image, index) in imageArray" :key="index">
          <template v-bind:class="[ image.pathname.split('.')[1]==='mp4' ? 'video-container' : '']" #content>
            <b-img v-if="image.pathname.split('.')[1]!=='mp4'"
                   :src="image.largeImageFilepath || image.pathname || null | getAsset"
                   class="image_fluid sliderCursor centered"/>
            <video v-if="image.pathname.split('.')[1]==='mp4'" autoplay muted loop playsinline
                   class="sliderCursor centered">
              <source :src="image.pathname | getAsset" type="video/mp4">
            </video>
          </template>
        </VueperSlide>
      </VueperSlides>
    </b-modal>
  </div>
</template>

<script>
import RFButton from '@/components/forms/RFButton'
import {accommodations} from '@/enums'
import {buildingService} from '@/services'
import {getAsset} from '@/helpers';

export default {
  data() {
    return {
      showCarouselModal: false,
      indexSlide: 0,
      imageArray: [],
      innerWidth: 0,
    }
  },
  components: {
    RFButton,
  },
  props: {
    accommodation: {
      type: Object,
    },
    openedMap: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isSearchPage() {
      return this.$route.name === 'search-page'
    },
    isApartment() {
      return this.accommodation.resourceType === accommodations.APARTMENT.toUpperCase()
    },
    accommodationStyle() {
      return {
        '--color': this.isSearchPage
          ? this.isApartment
            ? '#67AEA6'
            : '#E26F5A'
          : 'initial',
      }
    },
  },
  created() {
    if (this.accommodation && this.accommodation.images) {
      this.accommodation.images.sort((a, b) => a.orderIndex - b.orderIndex);
    }
  },
  methods: {
    showModal(index) {
      this.indexSlide = index
      let obj = this.accommodation.images[this.indexSlide]
      this.imageArray = this.accommodation.images;
      this.imageArray.splice(this.indexSlide, 1);
      this.imageArray.unshift(obj)
      this.showCarouselModal = true
    },
    async selectAccommodation() {
      if (this.isApartment) {
        this.$store.commit('apartmentStore/setApartment', this.accommodation)
      } else {
        const {data: building} = await buildingService.getBuildingByName(this.accommodation.buildingName)
        this.$store.commit('buildingStore/setBuilding', building)
        // const reservation = {
        //   item: this.accommodation,
        //   type: accommodations.ROOM,
        //   buildingId: this.accommodation.buildingId,
        // }

        // this.$store.commit('reservationStore/setReservation', reservation)
      }

      this.$router.push({
        name: this.isApartment ? 'apartment-page' : 'building-page',
        params: {
          // id: this.isApartment ? this.accommodation.id : this.accommodation.buildingId,
          name: this.isApartment ? this.accommodation.name.replace(/\s/g, "").toUpperCase() : this.accommodation.buildingName,
          // type: this.isApartment ? 'apartment' : 'room',
        }
      })
    },
    mouseOverCard() {
      this.$emit('showMapCard')
    },
    mouseLeaveCard() {
      this.$emit('hideMapCard')
    },
  }
}
</script>

<style lang="scss" scoped>
.vueperslides__arrow svg {
  stroke-width: 3px;
}

.vueperslides__arrow {
  color: white;
}

.accommodation-item {
  background-color: $white;

  .accommodation-slider-container {
    border-bottom: 5px solid var(--color);

    .carrousel-slide {
      cursor: zoom-in;
    }
  }

  .accommodation-info {
    @include font-style($montserrat-bold, 'bold', $font-18);
    color: $dark-blue;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;

    .accommodation-address {
      @include font-style($montserrat, 'regular', $font-12);
    }

    .accommodation-price {
      @include font-style($montserrat, 'regular', $font-18);
      color: var(--color);

      .subtext {
        @include font-style($montserrat, 'regular', $font-12);
      }
    }

    .accommodation-icons {
      .icons {
        margin: 10px 0;
      }

      .accommodation-icon {
        @include font-style($montserrat, 'regular', $font-12);
        margin-right: 15px;
      }

      .accommodation-image {
        width: 50px;
        padding-right: 20px;
      }
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  .accommodation-item {
    padding: 0;

    .accommodation-info {
      .accommodation-icons {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
</style>

<style css>
.modal-header {
  background-color: transparent !important;
  padding: 0px !important;
  margin: 0px !important;
  border: 0px !important;
}

.image_fluid {
  object-fit: scale-down;
  max-width: 100%;
  max-height: 100%;
}

.vueperslides__arrow {
  color: white;
}

.modal-dialog {
  max-width: 95vw;
  max-height: 95vh;
  padding: 5px;
}

.modal-content {
  text-align: center !important;
  border-radius: 0 !important;
  border: 0px solid white !important;
  background-color: transparent !important;
}

.modal-header {
  background-color: #24425b;
  color: white;
  border-radius: 0;
}

.modal .close {
  outline: none;
  color: white;
}

.vueperslide--loading .vueperslide__content-wrapper {
  display: none !important;
}

</style>
