<template>
  <b-row class="apartment-list">
    <b-col cols="12" class="mb-4" v-for="(item, index) in paginatedList" :key="item.id">
      <accommodation-item :id="index" :accommodation="item" :opened-map="openedMap" @showMapCard="sendCardIndex(index)" @hideMapCard="sendCardIndex(null)"/>
    </b-col>
    <b-col class="d-flex justify-content-center" v-if="currentPage < maxPages - 1">
      <RFButton id="load-more-btn" :label="$t('Load more')" :background="color" @on-click="updateList(true)" v-if="!loading"/>
      <RFLoader :size="30" :color="color" border-color="#fff" v-else/>
    </b-col>
  </b-row>
</template>

<script>
import AccommodationItem from '@/components/accomodations/AccommodationItem'
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'

export default {
  components: {
    AccommodationItem,
    RFButton,
    RFLoader,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    openedMap: {
      type: Boolean,
      default: false,
    },
    pageSize: {
      type: Number,
      default: 50,
    },
  },
  data() {
    return {
      currentPage: 0,
      paginatedList: [],
      lists: [],
      loading: false,
    }
  },
  computed: {
    totalItems() {
      return this.list.length
    },
    maxPages() {
      return Math.ceil(this.totalItems / this.pageSize)
    },
    color() {
      return this.$parent.$parent.themeColor === 'blue' ? '#24425B' : '#67AEA6'
    }
  },
  mounted() {
    this.generatePaginatedList()
  },
  methods: {
    generatePaginatedList() {
      let currentIndex = 0
      this.lists = []

      for (let i = 0; i < this.maxPages; i++) {
        const offset = currentIndex + this.pageSize
        const subList = this.list.slice(currentIndex, offset)
        currentIndex = offset
        this.lists.push(subList)
      }

      if (this.maxPages > 0) {
        this.updateList()
      }
    },
    updateList(nextPage = false) {
      this.loading = true

      window.setTimeout(() => {
        if (this.currentPage < this.maxPages - 1) {
          if (nextPage) {
            this.currentPage++
            this.lists[this.currentPage].forEach(item => this.paginatedList.push(item))
          } else {
            this.buildPaginatedList()
          }
        } else {
          this.buildPaginatedList()
        }

        this.loading = false
      }, 500)
    },
    buildPaginatedList() {
      this.paginatedList = []

      for (let i = 0; i <= this.currentPage; i++) {
        this.lists[i].forEach(item => this.paginatedList.push(item))
      }
    },
    sendCardIndex(index) {
      this.$emit('mapCardIndex', index)
    }
  },
  watch: {
    list: {
      handler() {
        this.generatePaginatedList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
